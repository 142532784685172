import { Component, OnInit, Input } from '@angular/core';
import { OrderService } from '../order/order.service';

@Component({
  selector: 'app-ttt',
  templateUrl: './ttt.component.html',
  styleUrls: ['./ttt.component.scss']
})
export class TttComponent implements OnInit {

  // @Input() orders: Array<any>;
  // @Input() companiesHash;
  // @Input() usersHash;
  companiesHash = {};
  usersHash = {};
  context = 'all';

  headerValues: {
    generatedAt: string,
    monthAndYear: string,
    todayDate: string,
    todayDay: string,
    company: string,
    paymentMethod: string
  };

  orders: Array<any> = [];


  // @ViewChild('d1') d1:ElementRef;

  constructor(public orderService: OrderService) { }

  ngOnInit() {

    this.orderService.ordersForPrint$.subscribe((orders: any) => {
      this.orders = orders;
    });

    this.orderService.companiesHash$.subscribe((companiesHash) => {
      this.companiesHash = companiesHash;
    });

    this.orderService.usersHash$.subscribe((usersHash) => {
      this.usersHash = usersHash;
    });

    this.orderService.headerValues$.subscribe((headerValues: any) => {
      this.headerValues = headerValues;
    });

    this.orderService.ordersFilterContext$.subscribe((context: any) => {
      this.context = context;
    });

  }

  getTotalCost() {

    if (!this.orders.length) {
      return 0;
    }

    return this.orderService.round(this.orders.map(o => o.orderPrice).reduce((acc, value) => acc + value, 0));
  }

}
