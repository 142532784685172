import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDatepicker, MAT_DATE_FORMATS } from '@angular/material';
import { OrderService } from './order.service';
import { FormControl } from '@angular/forms';

import { pipe } from 'rxjs';
import { map, filter, pairwise, startWith } from 'rxjs/operators';

import { Moment } from 'moment';
import * as moment from 'moment';
import 'moment/locale/de-at';

import { Company } from '../company/company';
import { CompanyService } from '../company/company.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { FoodService } from '../food/food.service';
import { ActivatedRoute } from '@angular/router';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class OrderComponent implements OnInit {

  pdfGeneratedAt = moment().format('DD/MM/YYYY HH:mm');
  showPrint = false;

  emptyOption = null;

  companies: Array<Company> = [];
  companiesHash = {};
  filteredCompanies: Array<Company> = [];

  usersHash = {};

  displayedColumns: string[] = ['#', 'companyId', 'userId', 'orderDate', 'orderDay', 'orderTypeId', 'paymentMethodId', 'orderPrice'];
  displayedOrderDetailsColumns: string[] = ['foodQuantity', 'foodName', 'foodPrice', 'foodTotalPrice'];
  dataSource: MatTableDataSource<any>;
  expandedElement;

  ordersLoaded = false;
  ordersLength = 0;

  chosenMonthOrders = [];

  dateToday = moment().format('LL');
  dayToday = moment().format('dddd');

  filter = {
    date: new FormControl(moment()),
    company: new FormControl(),
    paymentMethod: new FormControl(),
    orderType: new FormControl(),
  };

  context = 'all';

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(public orderService: OrderService, private companyService: CompanyService, private foodService: FoodService,
              private route: ActivatedRoute) { }

  companySelected(e) {
    this.filterCompanies();
  }

  paymentMethodSelected(e) {
    this.filterCompanies();
  }

  orderTypeSelected(e) {
    this.filterCompanies();
  }

  ngOnInit() {
    this.orderService.initOrderTypes();
    this.orderService.initPaymentMethods();
    this.initUsers();

    this.route.params.subscribe((params) => {

      if (typeof params === 'undefined' || typeof params.context === 'undefined') {
        return;
      }

      // reset filters
      this.filter = {
        date: new FormControl(moment()),
        company: new FormControl(),
        paymentMethod: new FormControl(),
        orderType: new FormControl(),
      };

      this.context = params.context;

      this.orderService.pushOrdersFilterContext(this.context);

      this.initOrders();
      this.initCompanies();

    });

  }

  initCompanies() {
    this.companyService.getAllCompanies().subscribe((companies) => {
      this.companies = companies;
      companies.forEach((c) => {
        this.companiesHash[c.id] = c;
      });
      this.filteredCompanies = companies;

      this.orderService.pushCompaniesHash(this.companiesHash);
    });
  }

  initOrders(month = moment().format('MM/YYYY'), force = false) {

    let orders = this.orderService.getOrderByMonth(month);

    if (this.context === 'today') {
      orders = this.orderService.getOrderByDate(this.dateToday);
    }

    orders.pipe(map((order) => {

      order.forEach((o) => {

        let totalPrice = 0;
        o.food.forEach(food => {
          totalPrice += (food.price * food.quantity);
          food.obj = this.foodService.allFoodHash[food.foodId];
          food.totalPrice = this.orderService.round(food.price * food.quantity);
        });

        o.orderPrice = this.orderService.round(totalPrice);
      });

      return order.sort(this.sortOrdersFn);

    })).subscribe((orders2) => {
      this.chosenMonthOrders = orders2.slice();
      this.loadTable(orders2);
      if (force) {
        this.filterCompanies();
      }
    });
  }

  filterCompanies() {
    const data = this.chosenMonthOrders.filter((order) => {

      let keep = true;

      const checkCompany = this.filter.company && this.filter.company.value !== null;
      const checkMethod = this.filter.paymentMethod && this.filter.paymentMethod.value !== null;
      const checkType = this.filter.orderType && this.filter.orderType.value !== null;

      if (checkCompany && order.companyId.toString() !== this.filter.company.value.id) {
        keep = false;
      }

      if (checkMethod && order.paymentMethodId.toString() !== this.filter.paymentMethod.value.id) {
        keep = false;
      }

      if (checkType && order.orderTypeId.toString() !== this.filter.orderType.value.id) {
        keep = false;
      }

      return keep;
    });

    this.loadTable(data);
    this.filteredCompanies = this.companies.slice();
  }

  filterCompany(company) {
    const data = this.chosenMonthOrders.filter((order) => {
        return order.companyId.toString() === company.id;
    });
    this.loadTable(data);
  }

  loadTable(data) {
    this.orderService.ordersForPrint(data);
    this.dataSource = new MatTableDataSource<any>(data);
    // this.dataSource.paginator = this.paginator;
    this.ordersLoaded = true;
    this.ordersLength = data.length;
  }

  pageSizeOptions() {

    const pages = [20, 50, 100];
    // if (this.ordersLength < 20) {
    //   return pages;
    // }

    // pages.push(this.ordersLength);

    return pages;
  }

  setPageSize() {
    return this.ordersLength < 20 ? 20 : this.ordersLength || 1000;
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.filter.date.value;
    ctrlValue.year(normalizedYear.year());
    this.filter.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.filter.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.filter.date.setValue(ctrlValue);

    this.initOrders(moment(this.filter.date.value).format('MM/YYYY'), true);

    datepicker.close();
  }

  printOrder() {
    this.pdfGeneratedAt = moment().format('DD/MM/YYYY HH:mm');
    this.showPrint = true;

    const headerData: {
      generatedAt: string,
      monthAndYear: string,
      todayDate: string,
      todayDay: string,
      company: string,
      paymentMethod: string
    } = {
      generatedAt: this.pdfGeneratedAt,
      monthAndYear: this.filter.date && this.filter.date.value && this.filter.date.value.format('MM/YYYY'),
      todayDate: this.dateToday,
      todayDay: this.dayToday,
      company: this.filter.company && this.filter.company.value && this.filter.company.value.name,
      paymentMethod: this.filter.paymentMethod && this.filter.paymentMethod.value && this.filter.paymentMethod.value.name
    };

    this.orderService.pushHeaderValues(headerData);

    window.setTimeout(() => {
      window.print();
    }, 100);
  }

  getTotalCost() {
    return this.orderService.round(this.dataSource.data.map(o => o.orderPrice).reduce((acc, value) => acc + value, 0));
  }

  initUsers() {
    this.orderService.getAllUsers().subscribe((users) => {
      users.forEach((user) => {
        user.name = user.firstName + ' ' + user.lastName;
        this.usersHash[user.id] = user;
      });

      this.orderService.pushUsersHash(this.usersHash);
    });
  }

  getOptionText(option) {
    return option && option.name || '';
  }

  sortOrdersFn(a, b) {

    if (moment(a.orderDate, 'LL').isBefore(moment(b.orderDate, 'LL'))) {
      return -1;
    }

    if (moment(a.orderDate, 'LL').isAfter(moment(b.orderDate, 'LL'))) {
      return 1;
    }

    return 0;
  }
}
