import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { Menu } from './menu';
import { MenuService } from './menu.service';
import * as moment from 'moment';
import 'moment/locale/de-at';
import { Calendar } from 'primeng/calendar';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements AfterViewInit {

  // @ViewChild('week1Calendar', {static: false}) week1Calendar: Calendar;
  // @ViewChild('week2Calendar', {static: false}) week2Calendar: Calendar;

  displayedColumns: string[] = ['name', 'description', 'position', 'cityKey', 'actions'];
  dataSource: Menu[];

  originalDataSource: Menu[];

  // editingWeek = false;
  // weekNumbers = {
  //   week1: undefined,
  //   week2: undefined
  // };

  msgSaved = '';

//   de = {
//     firstDayOfWeek: 1,
//     dayNames: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
//     dayNamesShort: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
//     dayNamesMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
//     monthNames: [ "Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember" ],
//     monthNamesShort: [ "Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez" ],
//     today: 'Heute',
//     clear: 'Clear',
//     dateFormat: 'mm/dd/yy',
//     weekHeader: 'Wk'
// };

  constructor(private menuService: MenuService) { }

  ngAfterViewInit() {
    this.menuService.getAllMenues().subscribe((menu) => {
      this.dataSource = menu;

      // this.dataSource.forEach((item) => {
      //   // console.log('tem', item.fullWeek)
      //   if (item.week === '1' && item.fullWeek) {
      //     const w = item.fullWeek.split(' - ');
      //     this.weekNumbers.week1 = [new Date(w[0]), new Date(w[1])];
      //   }
      //   if (item.week === '2' && item.fullWeek) {
      //     const w = item.fullWeek.split(' - ');
      //     this.weekNumbers.week2 = [new Date(w[0]), new Date(w[1])];
      //   }
      // });

      this.originalDataSource = [...[], ...this.dataSource];
    });
  }

  // getMenuExplanation(menu) {

  //   if (!menu.fullWeek) {
  //     return '';
  //   }

  //   const arr = menu.fullWeek.split(' - ');

  //   if (!arr.length) {
  //     return;
  //   }

  //   return moment(arr[0]).format('L') + ' - ' + moment(arr[1]).format('L');
  // }

  // saveWeekCahnges() {
  //   if (typeof this.weekNumbers.week1 === 'undefined' || typeof this.weekNumbers.week2 === 'undefined') {
  //     alert('Sowohl Woche 1 als auch Woche 2 müssen eingestellt werden');
  //     return;
  //   }
  //   if (this.weekNumbers.week1[0].getDate() === this.weekNumbers.week2[0].getDate()) {
  //     alert('Die Daten für Woche 1 und Woche 2 müssen unterschiedlich sein');
  //     return;
  //   }

  //   const week1Number = moment(this.weekNumbers.week1[0]).week();
  //   const week2Number = moment(this.weekNumbers.week2[0]).week();

  //   this.dataSource.forEach((menu) => {
  //     console.log(menu);

  //     if (menu.type === 'daily' && menu.week && menu.week.length) {

  //       menu.day = menu.day.replace(/[0-9]/g, '');
  //       menu.day = menu.day + (menu.week === '1' ? week1Number : week2Number);
  //       if (menu.week === '1' && this.weekNumbers.week1 && this.weekNumbers.week1.length) {
  //         menu.fullWeek = this.weekNumbers.week1[0] + ' - ' + this.weekNumbers.week1[1];
  //       }

  //       if (menu.week === '2' && this.weekNumbers.week2 && this.weekNumbers.week2.length) {
  //         menu.fullWeek = this.weekNumbers.week2[0] + ' - ' + this.weekNumbers.week2[1];
  //       }

  //       this.saveMenuItem(menu);

  //     }

  //   });
  // }

  saveMenuItem(obj) {
    const menuRef = this.menuService.updateMenu(obj);

    menuRef.then((menu) => {
      this.msgSaved = 'Wochenänderungen gespeichert...';

      setTimeout(() => {
        this.msgSaved = '';
      }, 2000);
    });
  }

  // onCalendarSelect(evt, orderNumber) {
  //   const start = new Date(evt);
  //   start.setDate(start.getDate() - start.getDay() + 1);
  //   this.weekNumbers['week' + orderNumber][0] = start;

  //   const end = new Date(start);
  //   end.setDate(start.getDate() + 6);
  //   this.weekNumbers['week' + orderNumber][1] = end;

  //   // this.week1Calendar.overlayVisible = false;
  //   // this.week2Calendar.overlayVisible = false;
  // }

  delete(obj: Menu) {
    if (confirm('Bist du sicher?')) {
      this.menuService.delete(obj.id).then((val) => {
        alert('Object deleted');
      }).catch((error) => {
        console.log('Remove failed: ' + error.message);
      });
    }
  }

}
