import { Component, OnInit } from '@angular/core';
import { UserService } from './user.service';
import { FoodService } from './food/food.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'admin';

  isLoggedIn = false;
  isModerator = false;
  isAdmin = false;
  name = '';

  constructor(private userService: UserService, private foodService: FoodService) {

    this.userService.initAuthStateChanged();

  }

  ngOnInit() {
    if (this.userService.user && this.userService.user.local) {
      this.name = this.userService.user.local.firstName + ' ' + this.userService.user.local.lastName;
      if (this.name.trim().length === 0) {
        this.name = 'Kein Name';
      }
    }

    this.userService.userObjectChanged$.subscribe((user) => {
      if (user.local) {
        this.name = user.local.firstName + ' ' + user.local.lastName;
        if (this.name.trim().length === 0) {
          this.name = 'Kein Name';
        }
        this.isAdmin = user.local.isAdmin && user.local.isAdmin.toString() === 'true';
        this.isModerator = user.local.isModerator && user.local.isModerator.toString() === 'true';
      }
      this.isLoggedIn = user.loggedIn;

      if (this.isLoggedIn) {
        this.foodService.initAllFood();
      }
    });
  }

  logOut() {
    this.userService.logout();
  }
}
