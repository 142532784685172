import { Injectable } from '@angular/core';

import * as firebase from 'firebase';

import { AngularFireDatabase } from '@angular/fire/database';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from './shared/data.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private authStateChangeInitialized = false;

  userObjectChanged$: Subject<any> = new Subject();
  showFirebaseUi$: Subject<boolean> = new Subject();
  showEmailNotVerifiedMsg$: Subject<boolean> = new Subject();

  user: { loggedIn: boolean, firebase: any, local } = {
    loggedIn: false,
    firebase: undefined,
    local: undefined
  };

  constructor(private db: AngularFireDatabase, private router: Router, private dataService: DataService) { }

  initAuthStateChanged() {

    if (this.authStateChangeInitialized) {
      return;
    }

    this.authStateChangeInitialized = true;

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        user.getIdToken().then((accessToken) => {


          const type = user.providerData[0].providerId;

          const provider = type === 'email' || type === 'password' ? 'email' : 'phone';
          const value = type === 'email' || type === 'password' ? user.email : user.phoneNumber;

          const users = this.db.list('/user', ref => ref.orderByChild(provider).equalTo(value)).snapshotChanges();

          users.pipe(map(us => {
            return us.map((item: any) => {
              return { id: item.key, ...item.payload.val() };
            });
          })).subscribe((dUser) => {

            // stalno ga kreira kad se brise iz firebase base, mozda treba napraviti provjeru da li je korisnik dosao sa sign up page????
            if (!dUser.length) {
              const itemsRef = this.db.list('user');

              const userObj = {
                id: user.uid,
                companyId: '',
                email: provider === 'email' ? user.email : '',
                firstName: '',
                isAdmin: false,
                isModerator: false,
                inactive: false,
                lastName: '',
                phone: provider !== 'email' ? user.phoneNumber : ''
               };

              itemsRef.set(userObj.id, userObj);

              this.user = {
                loggedIn: true,
                firebase: user,
                local: userObj
              };

              this.userObjectChanged$.next(this.user);

              if (user.email !== null && !user.emailVerified) {
                user.sendEmailVerification();
                this.logout();
                this.showEmailNotVerifiedMsg$.next(true);
              } else {

                if (!userObj.isAdmin && !userObj.isModerator || (typeof userObj.inactive !== 'undefined' && userObj.inactive.toString() === 'true')) {
                  this.logout();
                  alert('you must be admin');
                  // this.showEmailNotVerifiedMsg$.next(true);
                } else {

                  if (userObj.isAdmin && userObj.isAdmin.toString() === 'true') {
                    this.router.navigate(['/menu']);
                  } else if (userObj.isModerator && userObj.isModerator.toString() === 'true') {
                    this.router.navigate(['/order/all']);
                  }

                }

              }


            } else {

              const userObj: any = dUser[0];

              this.user = {
                loggedIn: true,
                firebase: user,
                local: userObj
              };

              this.userObjectChanged$.next(this.user);

              // TODO: ovo treba ponovo ukljuciti
              // if (user.email !== null && !user.emailVerified) {
              //   user.sendEmailVerification();
              //   this.logout();
              //   this.showEmailNotVerifiedMsg$.next(true);
              //   return;
              // }

          console.log(user);


              if (!userObj.isAdmin && !userObj.isModerator || (typeof userObj.inactive !== 'undefined' && userObj.inactive.toString() === 'true')) {
                this.logout();
                alert('you must be admin');
                // this.showEmailNotVerifiedMsg$.next(true);
              } else {
                if (userObj.isAdmin && userObj.isAdmin.toString() === 'true') {
                  this.router.navigate(['/menu']);
                } else if (userObj.isModerator && userObj.isModerator.toString() === 'true') {
                  this.router.navigate(['/order/all']);
                }
              }
            }
          });

        });
      } else {
        // User is signed out.

        this.user = {
          loggedIn: false,
          firebase: undefined,
          local: undefined
        };

        this.userObjectChanged$.next(this.user);
      }

      return false;
    }, (error) => {
      console.log(error);
    });
  }

  logout() {
    firebase.auth().signOut();
    this.router.navigate(['/login']);
    this.showFirebaseUi$.next(true);
  }

  // TODO: move this to userService and rename this service to authSerivce!!!
  getUsersWithCompanyId(companyId) {
    return this.dataService.queryByProperty('user', 'companyId', companyId);
  }

  getAllUsers() {
    return this.dataService.getAll('user');
  }

  setInactive(userId) {
    return this.dataService.updateObject('user', userId, { inactive: 'true' });
  }

  removeFromCompany(userId) {
    return this.dataService.updateObject('user', userId, { companyId: '' });
  }

  setActive(userId) {
    return this.dataService.updateObject('user', userId, { inactive: 'false' });
  }

}
