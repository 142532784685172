import { Component, OnInit, ViewChild } from '@angular/core';
import { Menu } from '../menu';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuService } from '../menu.service';
import { FoodService } from 'src/app/food/food.service';
import { Food } from 'src/app/food/food';
import { StorageService } from 'src/app/shared/storage.service';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-menu',
  templateUrl: './edit-menu.component.html',
  styleUrls: ['./edit-menu.component.scss']
})
export class EditMenuComponent implements OnInit {

  @ViewChild('editMenuForm', {static: false}) private editMenuForm: NgForm;

  model: Menu = {
    id: '',
    name: '',
    day: '',
    description: '',
    image: '',
    position: '',
    type: '',
    icon: '',
    week: '',
    fullWeek: '',
    cityKey: '',
    active: 'true'
  };

  fullWeekFormated = '';

  // currently food in menu with changes
  foodInMenu: Food[] = [];
  // values before any changes
  originalFoodInMenu: Food[] = [];
  // availabe food to be assigned to menu
  allFoodWithoutMenu: Food[] = [];

  constructor(private route: ActivatedRoute, private menuService: MenuService, private foodService: FoodService,
              private router: Router, private storageService: StorageService) { }

  ngOnInit() {
    this.route.params.subscribe((params) => {

      if (typeof params === 'undefined' || typeof params.id === 'undefined' || params.id === 'new') {
        return;
      }

      this.menuService.getMenu(params.id).subscribe((item) => {
        const menu: any = item;
        menu.id = params.id;

        this.model = menu;

        this.weekFormated();
      }, (error) => {
        console.log(error);
      });

      this.foodService.getFoodWithMenuId(params.id).subscribe((food) => {
        this.foodInMenu = food;
        this.originalFoodInMenu = food.slice();
      });

      this.foodService.getAllFoodWithoutMenu().subscribe((food) => {
        this.allFoodWithoutMenu = food;
      });

      this.storageService.downloadUrlSubscriber$.subscribe((url) => {
        this.model.image = url;
      });

    });
  }

  addFoodToMenu(food: Food) {
    const index = this.foodInMenu.indexOf(food);
    if (index !== -1) {
      return;
    }

    const allFoodIndex = this.allFoodWithoutMenu.indexOf(food);
    if (allFoodIndex !== -1) {
      this.allFoodWithoutMenu.splice(allFoodIndex, 1);
    }

    this.foodInMenu.push(food);

  }

  removeFood(food: Food) {

    const index = this.foodInMenu.indexOf(food);
    if (index !== -1) {
      this.foodInMenu.splice(index, 1);
      this.allFoodWithoutMenu.push(food);
    }
  }

  submit() {

    if (!this.editMenuForm.valid) {
      return;
    }

    const updateObj = {
      id: this.model.id,
      name: this.model.name,
      day: this.model.day || '',
      description: this.model.description,
      image: this.model.image,
      type: this.model.type,
      position: this.model.position,
      icon: this.model.icon,
      week: this.model.week || '',
      active: this.model.active || '1'
    };

    if (updateObj.id) {
      this.update(updateObj);
      this.updateFoodItems();
      return;
    }

    this.create(updateObj);
    this.updateFoodItems();

  }

  update(obj) {
    const menuRef = this.menuService.updateMenu(obj);

    menuRef.then((menu) => {
      this.backToMenuList();
    });
  }

  create(obj) {
    delete obj.id;
    const menuRef = this.menuService.createMenu(obj);

    menuRef.then((menu) => {
      this.backToMenuList();
    });
  }

  updateFoodItems() {
    if (this.foodInMenu === this.originalFoodInMenu) {
      return;
    }

    this.foodInMenu.forEach((food) => {
      if (this.originalFoodInMenu.indexOf(food) === -1) {
        // TODO: change menuId to this.model.id
        const foodItem = food;
        foodItem.menuId = this.model.id;
        this.foodService.updateFood(foodItem);

      }
    });

    this.originalFoodInMenu.forEach((food) => {
      if (this.foodInMenu.indexOf(food) === -1) {
        // TODO: set menuId to empty

        const foodItem = food;
        foodItem.menuId = '';
        this.foodService.updateFood(foodItem);
      }
    });

  }

  onFileSelected() {
    const fileButton = document.getElementById('fileButton');
    fileButton.addEventListener('change', (e: any) => {
      this.storageService.uploadFile(e.target.files[0], this.model.name, 'menu');
    });
  }

  backToMenuList() {
    this.router.navigate(['/menu']);
  }

  weekFormated() {

    if (!this.model.fullWeek) {
      return '';
    }

    const arr = this.model.fullWeek.split(' - ');

    if (!arr.length) {
      return;
    }

    this.fullWeekFormated = moment(arr[0]).format('L') + ' - ' + moment(arr[1]).format('L');
    // return moment(arr[0]).format('L') + ' - ' + moment(arr[1]).format('L');
  }

}
