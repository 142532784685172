import { Injectable } from '@angular/core';
import { Food } from './food';
import { DataService } from '../shared/data.service';

@Injectable({
  providedIn: 'root'
})
export class FoodService {

  context = 'food';

  allFood: Array<Food> = [];
  allFoodHash: any = {};

  constructor(private dataService: DataService) { }

  getAllFood() {
    return this.dataService.getAll(this.context);
  }

  getFood(id: string) {
    return this.dataService.getById(this.context, id);
  }

  getFoodWithMenuId(menuId: string) {
    return this.dataService.queryByProperty(this.context, 'menuId', menuId);
  }

  getAllFoodWithoutMenu() {
    return this.dataService.queryByProperty(this.context, 'menuId', '');
  }

  updateFood(obj: Food) {
    const id = obj.id;
    delete obj.id;
    return this.dataService.updateObject(this.context, id, obj);
  }

  createFood(obj: Food) {
    return this.dataService.createObject(this.context, obj);
  }

  delete(id: string) {
    return this.dataService.deleteObject(this.context, id);
  }

  /////

  initAllFood() {
    this.getAllFood().subscribe((food) => {
      this.allFood = food;

      food.forEach(element => {
        this.allFoodHash[element.id] = element;
      });
    });
  }
}
