import { Component, OnInit } from '@angular/core';
import { DataService } from '../shared/data.service';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit {

  r2oUsers = [];
  r2oPaymentMethods = [];
  configuration = {};

  cities = [];

  constructor(private dataService: DataService) { }

  ngOnInit() {

    this.dataService.getAll('city').subscribe((data) => {
      this.cities = data;
    });

    this.dataService.getAll('users').subscribe((data) => {
      this.r2oUsers = data;
    });

    this.dataService.getAll('paymentmethods').subscribe((data) => {
      this.r2oPaymentMethods = data;
    });

    this.dataService.getAll('configuration').subscribe((data) => {

      console.log('sasad', data)

      const conf = {};
      data.forEach(el => {
        conf[el.id] = el.value;
      });

      this.configuration = conf;

      console.warn(this.configuration);
    });

  }

  chooseUser(user) {
    this.dataService.updateObject('configuration', 'r2o_user_id', {value: user.user_id});
  }

  choosePaymentMethod(pm, type) {
    const obj = {};
    obj[type] = {value: pm.payment_id};
    this.dataService.updateObject('configuration', 'r2o_payment_method/value', obj);
  }

}
