import { Component, OnInit } from '@angular/core';

import * as firebaseui from 'firebaseui';
import * as firebase from 'firebase';
import { UserService } from '../user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private userService: UserService) { }

  ngOnInit() {
    this.userService.initAuthStateChanged();
    this.startFirebaseUi();
  }

  startFirebaseUi() {

    const uiConfig = {
      signInFlow: 'popup',
      signInOptions: [
        {
          requireDisplayName: false,
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID
        }
      ],
      credentialHelper: firebaseui.auth.CredentialHelper.NONE,
      callbacks: {
        signInSuccessWithAuthResult: (resp) => {
          // this.verifyEmailPendingSnackbarRef.dismiss();
          return false;
        },
        // uiShown: () => {
        //   this.showSpinner = false;
        // }
      }
      // Other config options...
    };

    if (firebaseui.auth.AuthUI.getInstance()) {
      const ui = firebaseui.auth.AuthUI.getInstance();
      ui.start('#firebaseui-auth-container', uiConfig);
    } else {
      const ui = new firebaseui.auth.AuthUI(firebase.auth());
      ui.start('#firebaseui-auth-container', uiConfig);
    }
  }

}
