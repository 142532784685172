import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class R2oService {

  // private corsProxy = 'https://cors-anywhere.herokuapp.com/';
  private corsProxy = '';

  // private cloudFunctionBaseUrl = 'http://localhost:5001/delivery-2c20b/us-central1';
  private cloudFunctionBaseUrl = 'https://us-central1-road-diner-f06c9.cloudfunctions.net/syncData';

  // private baseUrl = 'http://ready2order-api.local/api';

  // private httpOptions = {
  //   headers: new HttpHeaders({
  //     // 'Content-Type':  'application/json',
  //     Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IktlbmFuIEFsaWJlZ292aWMiLCJwcm9kdWN0IjoicmVhZHkyb3JkZXIyZmlyZWJhc2UtYXBpIiwieWVhciI6IjIwMjAiLCJ3ZWJzaXRlIjoiaHR0cDovL2suYWxpYmVnb3ZpYy5jb20iLCJpYXQiOjE1MTYyMzkwMjJ9.LMPPi7JSwqZEnsfD2akzZzcLk79KvbYsNM9gBtVfsJI',
  //     'ka-r2o': 'ready2order2firebase-api/kalibegovic'
  //   })
  // };

//   API_GUARD_TOKEN=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IktlbmFuIEFsaWJlZ292aWMiLCJwcm9kdWN0IjoicmVhZHkyb3JkZXIyZmlyZWJhc2UtYXBpIiwieWVhciI6IjIwMjAiLCJ3ZWJzaXRlIjoiaHR0cDovL2suYWxpYmVnb3ZpYy5jb20iLCJpYXQiOjE1MTYyMzkwMjJ9.LMPPi7JSwqZEnsfD2akzZzcLk79KvbYsNM9gBtVfsJI
// API_GUARD_USER_AGENT=ready2order2firebase-api/kalibegovic

  constructor(private http: HttpClient) { }

  getData(r2oToken, cityKey, endpoint = 'products') {
    return this.http.post(this.corsProxy + this.cloudFunctionBaseUrl + '/syncData', {r2oToken: r2oToken, endpoint: endpoint, cityKey: cityKey  })
    // return this.http.get(this.baseUrl + '/products', { observe: 'response', headers: this.httpOptions.headers });
  }

}
