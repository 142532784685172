import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UserService } from '../user.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements AfterViewInit {

  displayedColumns: string[] = ['email', 'phone', 'firstName-lastName', 'companyId', 'isAdmin', 'isModerator', 'actions'];

  dataSource: [];
  constructor(private userService: UserService) { }

  ngAfterViewInit() {
    this.userService.getAllUsers().subscribe((users) => {
      this.dataSource = users;
    });
  }

  inactive(obj) {

    if (confirm('Bist du sicher?')) {
      this.userService.setInactive(obj.id).then((val) => {
        alert('User set as inactive');
      }).catch((error) => {
        console.log('Failed: ' + error.message);
      });
    }
  }

  active(obj) {

    if (confirm('Bist du sicher?')) {
      this.userService.setActive(obj.id).then((val) => {
        alert('User set as active');
      }).catch((error) => {
        console.log('Failed: ' + error.message);
      });
    }
  }

}
