import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Company } from '../company';
import { NgForm } from '@angular/forms';
import { CompanyService } from '../company.service';
import { UserService } from 'src/app/user.service';

@Component({
  selector: 'app-edit-company',
  templateUrl: './edit-company.component.html',
  styleUrls: ['./edit-company.component.scss']
})
export class EditCompanyComponent implements OnInit {

  @ViewChild('editCompanyForm', {static: false}) private editCompanyForm: NgForm;

  model: Company = {
    id: '',
    name: '',
    password: ''
  };

  users = [];

  constructor(private router: Router, private route: ActivatedRoute,
              private companyService: CompanyService, private userService: UserService) { }

  ngOnInit() {

    this.route.params.subscribe((params) => {

      if (typeof params === 'undefined' || typeof params.id === 'undefined' || params.id === 'new') {
        return;
      }

      this.companyService.getCompany(params.id).subscribe((item) => {
        const company: any = item;
        company.id = params.id;

        this.model = company;
      }, (error) => {
        console.log(error);
      });

      this.userService.getUsersWithCompanyId(params.id).subscribe((users) => {
        this.users = users;
      });

    });

  }

  submit() {

    if (!this.editCompanyForm.valid) {
      return;
    }

    const updateObj = {
      id: this.model.id,
      name: this.model.name,
      password: this.model.password,
    };

    if (updateObj.id) {
      this.update(updateObj);
      return;
    }

    this.create(updateObj);

  }

  update(obj) {
    const companyRef = this.companyService.updateCompany(obj);

    companyRef.then((company) => {
      this.backToCompanyList();
    });
  }

  create(obj) {
    delete obj.id;
    const companyRef = this.companyService.createCompany(obj);

    companyRef.then((company) => {
      this.backToCompanyList();
    });
  }

  backToCompanyList() {
    this.router.navigate(['/company']);
  }

  removeUser(user) {

    if (confirm('Bist du sicher?')) {
      this.userService.removeFromCompany(user.id).then(() => {});
    }

  }

}
