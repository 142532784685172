import { Injectable } from '@angular/core';
import { DataService } from '../shared/data.service';
import { City } from './city';

@Injectable({
  providedIn: 'root'
})
export class CityService {

  context = 'city';

  constructor(private dataService: DataService) { }

  getAllCities() {
    return this.dataService.getAll(this.context);
  }

  getCity(id: string) {
    return this.dataService.getById(this.context, id);
  }

  createCity(obj: City) {
    return this.dataService.createObject(this.context, obj);
  }

  updateCity(obj: City) {
    const id = obj.id;
    delete obj.id;
    return this.dataService.updateObject(this.context, id, obj);
  }

  delete(id: string) {
    return this.dataService.deleteObject(this.context, id);
  }
}
