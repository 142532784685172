import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UserService } from 'src/app/user.service';
import { City } from '../city';
import { CityService } from '../city.service';

@Component({
  selector: 'app-edit-city',
  templateUrl: './edit-city.component.html',
  styleUrls: ['./edit-city.component.scss']
})
export class EditCityComponent implements OnInit {

  @ViewChild('editCityForm', {static: false}) private editCityForm: NgForm;

  model: City = {
    id: '',
    name: '',
    key: ''
  };

  users = [];

  constructor(private router: Router, private route: ActivatedRoute,
              private cityService: CityService, private userService: UserService) { }

  ngOnInit() {

    this.route.params.subscribe((params) => {

      if (typeof params === 'undefined' || typeof params.id === 'undefined' || params.id === 'new') {
        return;
      }

      this.cityService.getCity(params.id).subscribe((item) => {
        const city: any = item;
        city.id = params.id;

        this.model = city;
      }, (error) => {
        console.log(error);
      });

      // this.userService.getUsersWithCompanyId(params.id).subscribe((users) => {
      //   this.users = users;
      // });

    });

  }

  submit() {

    if (!this.editCityForm.valid) {
      return;
    }

    const updateObj = {
      id: this.model.id,
      name: this.model.name,
      key: this.model.key,
      token: this.model.token,
    };

    if (updateObj.id) {
      this.update(updateObj);
      return;
    }

    this.create(updateObj);

  }

  update(obj) {
    const companyRef = this.cityService.updateCity(obj);

    companyRef.then((company) => {
      this.backToCityList();
    });
  }

  create(obj) {
    delete obj.id;
    const companyRef = this.cityService.createCity(obj);

    companyRef.then((company) => {
      this.backToCityList();
    });
  }

  backToCityList() {
    this.router.navigate(['/city']);
  }

  removeUser(user) {

    if (confirm('Bist du sicher?')) {
      this.userService.removeFromCompany(user.id).then(() => {});
    }

  }

}
