import { Component, OnInit } from '@angular/core';
import { DataService } from '../shared/data.service';
import { R2oService } from './r2o.service';

@Component({
  selector: 'app-sync',
  templateUrl: './sync.component.html',
  styleUrls: ['./sync.component.scss']
})
export class SyncComponent implements OnInit {

  activeContext = '';
  loading = {};
  done = {};
  error = {};

  // $router->get('company', 'ExampleController@company');
  //   $router->get('products', 'ExampleController@products');
  //   $router->get('product-groups', 'ExampleController@productGroups');
  //   $router->get('payment-methods', 'ExampleController@paymentMethods');
  //   $router->get('users', 'ExampleController@users');
  //   $router->get('bill-types', 'ExampleController@billTypes');
  //   $router->get('printer', 'ExampleController@printer');
  //   $router->get('invoice', 'ExampleController@invoice'); // TODO: make it POST

  targets = [{
    label: 'Produkte',
    key: 'products'
  },{
    label: 'Produktgruppen',
    key: 'product-groups'
  },{
    label: 'Zahlungsarten',
    key: 'payment-methods'
  },{
    label: 'Users',
    key: 'users'
  },]

  cities = [];

  constructor(private r2oService: R2oService, private dataService: DataService) { }

  ngOnInit() {
    this.dataService.getAll('city').subscribe((cities) => {
      this.cities = cities;
      console.log('ccccccccc', cities)

      cities.forEach(c => {
        this.loading[c.key] = {};
        this.done[c.key] = {};
        this.error[c.key] = {};
      });
    })
  }

  syncByCitiy(city) {
    // alert(city.name)
    this.sync(city);
  }

  sync(city, context = 'all') {

    if(!confirm('Bist du sicher?')) return;

    if (context === 'all') {
        this.targets.forEach((target) => {
          console.log('foreach', target, city.key);
          this.syncData(city, target.key);
        })
      return;
    }

    this.syncData(city, context);
  }

  syncData(city, context) {
    this.activeContext = context;

    this.loading[city.key][context] = true;
    this.done[city.key][context] = false;
    this.error[city.key][context] = false;

    setTimeout(() => {


      // if (context === 'products') {


        // TODO: napraviti u config isto da bude lista gradova i "company iz ready2order"


        // TODO: uzmi oba tokena tj. iz oba grada

        // const r2oToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYXBpLnJlYWR5Mm9yZGVyLmF0IiwianRpIjoiZElJeEVFUit3RWNPXC9jeE9wNVZudlBoM1JiWmFDc2tQSGJ2WUNVMHFWQzg9IiwiaWF0IjoxNjA4NzQ1MzcyLCJkYXRhIjp7ImNvbXBhbnlfaWQiOjEwMDcyMCwiaW50ZWdyYXRvcl9pZCI6MTAwMX19.g8jryBfu-k7vlR5A2M6weWsuDXzUShIp4HVzqX_AoRI';

        // const r2oToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYXBpLnJlYWR5Mm9yZGVyLmF0IiwianRpIjoiK2hVVlZUdVNvcmxLaUFvQ3Zvb1BnQWdnaUhGZHJzSVN4TTE4WGVpZlRsST0iLCJpYXQiOjE2MDY4MTcxMTgsImRhdGEiOnsiY29tcGFueV9pZCI6MTAwMDU2LCJpbnRlZ3JhdG9yX2lkIjo5Nzl9fQ.Gqn68H9VZ8iWjt5FLz5pRJernrLPmd_HqDbCMDWmdRI';

        // first need to clear the existing context db so the onWrite is triggered again!
        this.dataService.deleteNode(context).then((result) => {
          this.r2oService.getData(city.token, city.key, context).subscribe((resp: any) => {
            console.log('resp', resp);
            this.loading[city.key][context] = false
            this.done[city.key][context] = true;
          }, (err: any) => {
            console.warn('err', err);
            this.loading[city.key][context] = false
            this.error[city.key][context] = true;
          });
        })

      // }


    }, 2000);
  }

}
