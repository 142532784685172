import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import {
  MatToolbarModule, MatMenuModule, MatIconModule, MatCardModule,
  MatInputModule, MatProgressSpinnerModule, MatButtonModule, MatSelectModule,
  MatSidenavModule, MatListModule, MatRippleModule, MatSnackBarModule, MatTableModule, MatAutocompleteModule, MatPaginatorModule, MatDatepickerModule, MatNativeDateModule, DateAdapter, MAT_DATE_LOCALE, MatTabsModule
} from '@angular/material';

import {CalendarModule} from 'primeng/calendar';

import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';


import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RouterModule } from '@angular/router';
import { appRoutes } from './app.routes';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from 'src/environments/environment';
import { AngularFireDatabase } from '@angular/fire/database';
import { CompanyComponent } from './company/company.component';
import { FoodComponent } from './food/food.component';
import { MenuComponent } from './menu/menu.component';
import { OrderComponent } from './order/order.component';
import { UserComponent } from './user/user.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EditFoodComponent } from './food/edit-food/edit-food.component';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { EditMenuComponent } from './menu/edit-menu/edit-menu.component';
import { EditCompanyComponent } from './company/edit-company/edit-company.component';
import { TttComponent } from './ttt/ttt.component';
import { CityComponent } from './city/city.component';
import { EditCityComponent } from './city/edit-city/edit-city.component';
import { SyncComponent } from './sync/sync.component';
import { ConfigComponent } from './config/config.component';

import { HttpClientModule } from '@angular/common/http';
import { ConfigFormComponent } from './config/config-form/config-form.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CompanyComponent,
    FoodComponent,
    MenuComponent,
    OrderComponent,
    UserComponent,
    EditFoodComponent,
    EditMenuComponent,
    EditCompanyComponent,
    TttComponent,
    CityComponent,
    EditCityComponent,
    SyncComponent,
    ConfigComponent,
    ConfigFormComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(
      appRoutes,
      // { enableTracing: true } // <-- debugging purposes only
      { useHash: true }
    ),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule ,
    MatToolbarModule,
    MatMenuModule, MatIconModule, MatCardModule,
    MatInputModule, MatProgressSpinnerModule, MatButtonModule, MatSelectModule,
    MatSidenavModule, MatListModule, MatRippleModule, MatSnackBarModule,
    MatTableModule, MatAutocompleteModule, MatPaginatorModule, MatDatepickerModule,
    MatTabsModule,
    MatNativeDateModule,
    CalendarModule,
    HttpClientModule
  ],
  providers: [AngularFireAuthGuard, AngularFireAuth, AngularFireDatabase, MatDatepickerModule, {
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
