import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FoodService } from '../food.service';
import { Food } from '../food';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuService } from 'src/app/menu/menu.service';

import { StorageService } from 'src/app/shared/storage.service';

@Component({
  selector: 'app-edit-food',
  templateUrl: './edit-food.component.html',
  styleUrls: ['./edit-food.component.scss']
})
export class EditFoodComponent implements OnInit {

  @ViewChild('editFoodForm', {static: false}) private editFoodForm: NgForm;

  model: Food = {
    id: '',
    menuId: '',
    name: '',
    description: '',
    // alergen: '',
    image: '',
    price: 0,
    currency: '',
    subtype: '',
    recommendText: ''
  };

  menues = [];

  constructor(private foodService: FoodService, private router: Router, private route: ActivatedRoute,
              private menuService: MenuService, private storageService: StorageService) { }

  ngOnInit() {

    this.route.params.subscribe((params) => {
      if (typeof params === 'undefined' || typeof params.id === 'undefined' || params.id === 'new') {
        this.menuService.getAllMenues().subscribe((menus) => {
          this.menues = menus;
        });
        return;
      }

      this.foodService.getFood(params.id).subscribe((item) => {
        const food: any = item;
        food.id = params.id;

        this.model = food;
      }, (error) => {
        console.log(error);
      });

      this.menuService.getAllMenues().subscribe((menus) => {
        this.menues = menus;
      });

      this.storageService.downloadUrlSubscriber$.subscribe((url) => {
        this.model.image = url;
      });

    });

  }

  submit() {

    if (!this.editFoodForm.valid) {
      return;
    }

    const updateObj = {
      id: this.model.id,
      menuId: this.model.menuId,
      name: this.model.name,
      description: this.model.description,
      recommendText: this.model.recommendText,
      // alergen: this.model.alergen,
      price: this.model.price || 0
    };

    if (this.model.subtype) {
     updateObj['subtype'] = this.model.subtype;
    }

    if (this.model.image) {
      updateObj['image'] = this.model.image;
    }

    if (updateObj.id) {
      this.update(updateObj);
      return;
    }

    this.create(updateObj);

  }

  update(obj) {
    const foodRef = this.foodService.updateFood(obj);

    foodRef.then((food) => {
      this.backToFoodList();
    });
  }

  create(obj) {
    delete obj.id;
    const foodRef = this.foodService.createFood(obj);

    foodRef.then((food) => {
      this.backToFoodList();
    });
  }

  backToFoodList() {
    this.router.navigate(['/food']);
  }

  onFileSelected() {
    const fileButton = document.getElementById('fileButton');
    fileButton.addEventListener('change', (e: any) => {
      this.storageService.uploadFile(e.target.files[0], this.model.name, 'food');
    });
  }

}
