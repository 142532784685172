import { Component, AfterViewInit } from '@angular/core';
import { Company } from './company';
import { CompanyService } from './company.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements AfterViewInit {

  displayedColumns: string[] = ['name', 'password', 'actions'];
  dataSource: Company[];

  constructor(private companyService: CompanyService) { }

  ngAfterViewInit() {
    this.companyService.getAllCompanies().subscribe((companies) => {
      this.dataSource = companies;
    });
  }

  delete(obj: Company) {
    if (confirm('Bist du sicher?')) {
      this.companyService.delete(obj.id).then((val) => {
        alert('Object deleted');
      }).catch((error) => {
        console.log('Remove failed: ' + error.message);
      });
    }
  }

}
