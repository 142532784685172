import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/shared/data.service';

@Component({
  selector: 'app-config-form',
  templateUrl: './config-form.component.html',
  styleUrls: ['./config-form.component.scss']
})
export class ConfigFormComponent implements OnInit {

  // TODO: napravi da se gleda koji je grad i proslijedi u cloud functions "key" i onda cloud func da trazi token
  // koji se treba prebaciti u drugu tabelu "city-tokens" a ona ne smije biti dostupna nikom

  r2oUsers = [];
  r2oPaymentMethodsCash = [];
  r2oPaymentMethodsCard = [];
  configuration = {};

  @Input() city: any;

  constructor(private dataService: DataService) { }

  ngOnInit() {
    console.log('ccc', this.city);

    this.dataService.getById('configuration', this.city.key).subscribe((configuration: any) => {

      console.log('conf configuration', configuration)

      console.log('d conf', configuration)

      this.dataService.queryByProperty('users', 'city_key', this.city.key).subscribe((data) => {

        console.log('gore data', data);


        data.map((u) => {

          const user = u;
          user.active = configuration && configuration.r2o_user_id.value.toString() === u.id.toString();

          return user;
        })

        this.r2oUsers = data;
      });

      this.dataService.queryByProperty('paymentmethods', 'city_key', this.city.key).subscribe((data) => {

        const cash = JSON.parse(JSON.stringify(data));
        const card = JSON.parse(JSON.stringify(data));

        if (configuration && configuration.r2o_payment_method && configuration.r2o_payment_method.value) {

          if (configuration.r2o_payment_method.value.cash) {
            cash.map((pm) => {
              const paymentMethod = pm;
              paymentMethod.active = configuration.r2o_payment_method.value.cash.value.toString() === paymentMethod.id.toString();
              return paymentMethod;
            });
          }

          if (configuration.r2o_payment_method.value.creditCard) {

            card.map((pm) => {
              const paymentMethod = pm;
              paymentMethod.active = configuration.r2o_payment_method.value.creditCard.value.toString() === paymentMethod.id.toString();
              return paymentMethod;
            });
          }

        }


        this.r2oPaymentMethodsCash = cash;
        this.r2oPaymentMethodsCard = card;
      });

    });



    // this.dataService.getAll('configuration').subscribe((data) => {

    //   console.log('sasad', data)

    //   const conf = {};
    //   data.forEach(el => {
    //     conf[el.id] = el;
    //     Object.entries(el).forEach((element: any) => {
    //       conf[el.id][element.id] = element.value;
    //     });
    //   });

    //   this.configuration = conf;

    //   console.warn(this.configuration);
    // });

  }

  chooseUser(user) {
    this.dataService.updateObject('configuration', this.city.key + '/r2o_user_id', {value: user.user_id});
  }

  choosePaymentMethod(pm, type) {
    const obj = {};
    obj[type] = {value: pm.payment_id};
    this.dataService.updateObject('configuration', this.city.key + '/r2o_payment_method/value', obj);
  }

}
