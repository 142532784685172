// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAxCEarq6G8e4R4A7-ndm50Q-_um99BJHw",
    authDomain: "road-diner-f06c9.firebaseapp.com",
    databaseURL: "https://road-diner-f06c9.firebaseio.com",
    projectId: "road-diner-f06c9",
    storageBucket: "road-diner-f06c9.appspot.com",
    messagingSenderId: "1065223649313",
    appId: "1:1065223649313:web:abf177a9e4b814f6d90b60",
    measurementId: "G-86D5CLWLP5"
  },
  // firebase: {
  //   apiKey: "AIzaSyAxCEarq6G8e4R4A7-ndm50Q-_um99BJHw",
  //   // authDomain: "road-diner-f06c9.firebaseapp.com",
  //   databaseURL: "http://localhost:9009/?ns=road-diner-f06c9",
  //   projectId: "road-diner-f06c9",
  //   // storageBucket: "road-diner-f06c9.appspot.com",
  //   // messagingSenderId: "1065223649313",
  //   // appId: "1:1065223649313:web:abf177a9e4b814f6d90b60",
  //   // measurementId: "G-86D5CLWLP5"
  // },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
