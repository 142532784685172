import { Injectable } from '@angular/core';
import { Menu } from './menu';
import { DataService } from '../shared/data.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  context = 'menu';

  constructor(private dataService: DataService) { }

  getAllMenues() {
    return this.dataService.getAll(this.context);
  }

  getMenu(id: string) {
    return this.dataService.getById(this.context, id);
  }

  updateMenu(obj: Menu) {
    const id = obj.id;
    delete obj.id;
    return this.dataService.updateObject(this.context, id, obj);
  }

  createMenu(obj: Menu) {
    return this.dataService.createObject(this.context, obj);
  }

  delete(id: string) {
    return this.dataService.deleteObject(this.context, id);
  }
}
