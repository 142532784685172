import { Component, AfterViewInit } from '@angular/core';
import { FoodService } from './food.service';
import { Food } from './food';

@Component({
  selector: 'app-food',
  templateUrl: './food.component.html',
  styleUrls: ['./food.component.scss']
})
export class FoodComponent implements AfterViewInit {

  displayedColumns: string[] = ['name', 'description', 'cityKey', 'recommendText', 'price', 'actions'];
  dataSource: Food[];
  constructor(private foodService: FoodService) { }

  ngAfterViewInit() {
    this.foodService.getAllFood().subscribe((food) => {
      this.dataSource = food;
    });
  }

  delete(obj: Food) {
    if (confirm('Bist du sicher?')) {
      this.foodService.delete(obj.id).then((val) => {
        alert('Object deleted');
      }).catch((error) => {
        console.log('Remove failed: ' + error.message);
      });
    }
  }

}
