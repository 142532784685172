import { Injectable } from '@angular/core';
import { DataService } from '../shared/data.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  context = 'order';

  paymentMethods = [];
  paymentMethodsHash = {};

  orderTypes = [];
  orderTypesHash = {};

  ordersForPrint$ = new Subject();
  companiesHash$ = new Subject();
  usersHash$ = new Subject();
  headerValues$ = new Subject();
  ordersFilterContext$ = new Subject();

  constructor(private dataService: DataService) { }

  getAllOrders() {
    return this.dataService.getAll(this.context);
  }

  getOrder(id: string) {
    return this.dataService.getById(this.context, id);
  }

  getOrderByMonth(monthYear) {
    return this.dataService.queryByProperty(this.context, 'orderMonthYear', monthYear);
  }

  getOrderByDate(date) {
    return this.dataService.queryByProperty(this.context, 'orderDate', date);
  }

  initOrderTypes() {
    this.dataService.getAll('orderType').subscribe((types) => {
      this.orderTypes = types;

      this.orderTypes.forEach(element => {
        this.orderTypesHash[element.id] = element;
      });
    });
  }

  initPaymentMethods() {
    this.dataService.getAll('paymentMethod').subscribe((methods) => {
      this.paymentMethods = methods;

      this.paymentMethods.forEach(element => {
        this.paymentMethodsHash[element.id] = element;
      });

    });
  }

  getAllUsers() {
    return this.dataService.getAll('user');
  }

  ordersForPrint(orders) {
    this.ordersForPrint$.next(orders);
  }

  pushCompaniesHash(companiesHash) {
    this.companiesHash$.next(companiesHash);
  }

  pushUsersHash(usersHash) {
    this.usersHash$.next(usersHash);
  }

  pushHeaderValues(headerValues) {
    this.headerValues$.next(headerValues);
  }

  pushOrdersFilterContext(context) {
    this.ordersFilterContext$.next(context);
  }

  round(value, decimals = '2') {
    return Number(Math.round((value + 'e' + decimals) as any) + 'e-' + decimals);
}

}
