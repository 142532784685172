import { Component, AfterViewInit } from '@angular/core';
import { City } from './city';
import { CityService } from './city.service';

@Component({
  selector: 'app-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.scss']
})
export class CityComponent implements AfterViewInit {

  displayedColumns: string[] = ['name', 'key', 'actions'];
  dataSource: City[];

  constructor(private cityService: CityService) { }

  ngAfterViewInit() {
    this.cityService.getAllCities().subscribe((cities) => {
      this.dataSource = cities;
    });
  }

  delete(obj: City) {
    if (confirm('Bist du sicher?')) {
      this.cityService.delete(obj.id).then((val) => {
        alert('Object deleted');
      }).catch((error) => {
        console.log('Remove failed: ' + error.message);
      });
    }
  }

}
