import { Injectable } from '@angular/core';
import { DataService } from '../shared/data.service';
import { Company } from './company';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  context = 'company';

  constructor(private dataService: DataService) { }

  getAllCompanies() {
    return this.dataService.getAll(this.context);
  }

  getCompany(id: string) {
    return this.dataService.getById(this.context, id);
  }

  createCompany(obj: Company) {
    return this.dataService.createObject(this.context, obj);
  }

  updateCompany(obj: Company) {
    const id = obj.id;
    delete obj.id;
    return this.dataService.updateObject(this.context, id, obj);
  }

  delete(id: string) {
    return this.dataService.deleteObject(this.context, id);
  }
}
