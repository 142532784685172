import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  downloadUrlSubscriber$: Subject<string> = new Subject();

  constructor(private storage: AngularFireStorage) { }

  uploadFile(file, originalName, context) {

    const foodName = originalName && originalName.length || context;
    const imgExtension = file.name.split('.').pop();
    const name = foodName + '_' + new Date().valueOf() + '' + Math.floor(Math.random() * (999 - 100 + 1) + 100) + '.' + imgExtension;
    const filePath = context + '/' + name;

    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe((url) => {
          this.downloadUrlSubscriber$.next(url);
        });
      })
    )
      .subscribe();
  }
}
